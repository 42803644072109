<template>
  <div class="form-group" data-test="ship-summary">
    <h6>{{ $t('view.ship.title') }}</h6>
    <dl>
      <dt>{{ $t('view.ship.name') }}</dt>
      <dd data-test="ship-name">{{ vessel.shipName }}</dd>

      <dt>{{ $t('generic.abbr.imo') }}</dt>
      <dd data-test="ship-imo">{{ vessel.shipNumber }}</dd>

      <template v-if="vessel.category">
        <dt>{{ $t('view.ship.type') }}</dt>
        <dd data-test="ship-category">{{ vessel.category }}</dd>
      </template>

      <template v-if="vessel.length && vessel.length.valueInCm">
        <dt>{{ $t('view.ship.length') }}</dt>
        <dd data-test="ship-length">{{ formatUnit(vessel.length.valueInCm / 100, 'meter', 'short', 2) }}</dd>
      </template>

      <template v-if="vessel.beam && vessel.beam.valueInCm">
        <dt>{{ $t('view.ship.beam') }}</dt>
        <dd data-test="ship-beam">{{ formatUnit(vessel.beam.valueInCm / 100, 'meter', 'short', 2) }}</dd>
      </template>

      <template v-if="movement && movement.draught">
        <dt>{{ $t('view.ship.draught') }}</dt>
        <dd data-test="ship-draught">{{ formatUnit(movement.draught / 100, 'meter', 'short', 2) }}</dd>
      </template>

      <template v-if="vessel.grossTonnage !== undefined">
        <dt>{{ $t('view.ship.grossTonnage') }}</dt>
        <dd data-test="ship-gross-tonnage">
          {{ formatUnit(vessel.grossTonnage, 't', null, 0, 2, '-') }}
        </dd>
      </template>

      <template v-if="vessel.nettTonnage !== undefined">
        <dt>{{ $t('view.ship.nettTonnage') }}</dt>
        <dd data-test="ship-nett-tonnage">
          {{ formatUnit(vessel.nettTonnage, 't', null, 0, 2, '-') }}
        </dd>
      </template>

      <template v-if="vessel.deadWeight !== undefined">
        <dt>{{ $t('view.ship.deadWeight') }}</dt>
        <dd data-test="ship-dead-weight">
          {{ formatUnit(vessel.deadWeight, 't', null, 0, 2, '-') }}
        </dd>
      </template>
    </dl>
  </div>
</template>

<script>
export default {
  name: 'ship-summary',
  props: {
    vessel: {
      required: true,
      type: Object,
    },
    movement: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
dl {
  display: grid;
  grid-template-columns: 1fr 2fr;

  dt {
    font-weight: normal;
  }
  dd {
    margin: 0;
  }
}
</style>
