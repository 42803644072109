import { mapGetters } from 'vuex';
import { DateTime } from 'luxon';

export default {
  data() {
    return {
      visit: null,
    };
  },
  watch: {
    'form.ucrn.$validValue': {
      handler: async function (ucrn) {
        if (!ucrn) {
          this.visit = null;
          return;
        }

        try {
          const visit = await this.$store.dispatch('LOAD_VISIT', ucrn);
          if (visit.failure) {
            this.visit = null;
            return;
          }

          if (visit?.vessel?.imo) {
            this.$store.dispatch('LOAD_SHIP', { ucrn, imo: visit.vessel?.imo, silent: true });
          }

          this.visit = visit ? visit : null;
        } catch {
          this.visit = null;
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      vesselsByImo: 'VESSELS_BY_IMO',
    }),

    selectedVessel() {
      return this.vesselsByImo[this.visit?.vessel?.imo];
    },

    berthOptions() {
      return (this.visit?.berthVisits ?? [])
        .filter((visit) => !visit.atd)
        .sort((a, b) => DateTime.fromISO(a.eta).valueOf() > DateTime.fromISO(b.eta).valueOf())
        .map((berthVisit) => ({
          value: berthVisit.externalId,
          text: `${berthVisit.berth.name} (${berthVisit.berth.code}) ${
            berthVisit.eta ? this.formatDate(berthVisit.eta, 'DATETIME_SHORT') : ''
          }`,
        }));
    },

    movementOptions() {
      const movements = this.visit?.movements ?? [];
      const berthVisits = this.visit?.berthVisits ?? -1;

      const textFor = (berth = {}, port) => {
        const { name, code } = berth;
        return [name ?? port, code && `(${code})`].join(' ').trim();
      };

      return movements.map((movement) => {
        const { berth: fromBerth } =
          berthVisits.find((berthVisit) => berthVisit.externalId === movement.idFromBerth) ?? {};
        const { berth: toBerth } = berthVisits.find((berthVisit) => berthVisit.externalId === movement.idToBerth) ?? {};

        return {
          value: movement.commId,
          text: [
            textFor(fromBerth, this.visit?.portOrigin),
            '\u279F',
            textFor(toBerth, this.visit?.portDestination),
          ].join(' '),
        };
      });
    },
  },
};
