<template>
  <model-form @submit="submit" :form="form" :model="model">
    <div class="form-group">
      <h1 class="form-group__title" v-if="title">{{ title }}</h1>
      <div v-if="explanation" v-markdown="explanation"></div>
    </div>

    <hr />
    <slot />
    <div class="row mt-3 mb-5 justify-content-between">
      <div class="col" data-test="cancel">
        <por-btn size="lg" @click="cancel" variant="secondary">{{ $t('form.cancel.button') }}</por-btn>
      </div>
      <div class="col">
        <por-btn size="lg" type="submit" :disabled="isSubmitting" variant="primary" class="float-right">
          {{ $t('form.submit') }}
          <loader v-if="isSubmitting" :inline="true" />
        </por-btn>
      </div>
    </div>
  </model-form>
</template>

<script>
import { mapActions } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import modelForm from 'poronline-shared-vue-components/components/form/model-form';
import PorBtn from 'poronline-shared-vue-components/components/por-btn';
import Loader from 'poronline-shared-vue-components/components/loader';
import Alert from 'poronline-shared-vue-components/components/modals/alert';

export default {
  name: 'EchoForm',
  components: { modelForm, Loader, PorBtn },
  props: {
    title: String,
    explanation: String,
    model: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    submitAction: String,
  },
  data() {
    return {
      isSubmitting: false,
    };
  },
  methods: {
    ...mapActions({
      openModal: 'OPEN_MODAL',
    }),
    async submit() {
      this.isSubmitting = true;
      const model = Object.freeze(cloneDeep(this.model));
      try {
        await this.$store.dispatch(this.submitAction, model);
      } catch (e) {
        this.isSubmitting = false;
        throw e;
      }
      this.$emit('submit', model);
      this.$router.push({ name: 'requests' });
      this.$notify({
        type: 'success',
        title: this.$t('form.submitSuccess.title'),
        text: this.$t('form.submitSuccess.text'),
      });
    },
    async cancel() {
      const result = await this.openModal({
        component: Alert,
        props: {
          title: this.$t('form.cancel.title'),
          okTitle: this.$t('form.cancel.buttonModal'),
          body: this.$t('form.cancel.description'),
        },
      });
      if (!result) {
        return;
      }
      this.$router.push({ name: 'requests' });
    },
  },
};
</script>
